import { Provider } from 'mobx-react'
import { appWithTranslation } from 'next-i18next'
import App from 'next/app'
import React from 'react'
import CanonicalURL from '../src/components/atoms/CanonicalURL'
import { getStores } from '../src/stores/Store'
import { checkRedirect } from '../src/utils/checkRedirect'
import '../styles.css'

class CustomApp extends App {
  stores = {}

  static async getInitialProps(appContext) {
    const stores = getStores()
    appContext.ctx.stores = stores
    const appProps = await App.getInitialProps(appContext)

    if (typeof window === 'undefined') {
      const needsRedirect = checkRedirect(appContext.ctx.asPath)
      if (needsRedirect) {
        appContext.ctx.res.writeHead(
          301,
          { Location: needsRedirect.redirect }
        ).end()
      }
    }

    return {
      ...appProps,
      initialMobxState: stores,
    }
  }

  constructor(props) {
    super(props)
    this.stores = getStores(props.initialMobxState)
  }

  render() {
    const { Component, pageProps } = this.props

    return (
      <Provider {...this.stores}>
        <CanonicalURL />
        <Component {...pageProps} />
      </Provider>
    )
  }
}

export default appWithTranslation(CustomApp)