import { useStaticRendering } from 'mobx-react'
import { ApplicationStores, Optional } from '../interfaces/ApplicationStores'

const isServer = typeof window === 'undefined'
useStaticRendering(isServer)

let store: ApplicationStores = null

export function getStores(initialData: Optional<ApplicationStores> = {}) {
  if (isServer) {
    return initializeStore(initialData)
  }

  if (store === null) {
    store = initializeStore(initialData)
  }

  return store
}

function initializeStore(initialData: Optional<ApplicationStores>): ApplicationStores {
  return {}
}