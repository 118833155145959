import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'

const CanonicalURL = (props: any) => {
  const domain = 'https://persono.com.br'
  const router = useRouter()
  const canonicalURL = domain + router.asPath

  return <Head>
    <link rel="canonical" href={canonicalURL} />
  </Head>
}

export default CanonicalURL